export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const FETCH_ACCOUNTS_REQUEST = "FETCH_ACCOUNTS_REQUEST";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";

export const CHANGE_SELECTED_ACCOUNT_ID = "CHANGE_SELECTED_ACCOUNT_ID";
export const CHANGE_SELECTED_ACCOUNT = "CHANGE_SELECTED_ACCOUNT";

export const PAYMENT_RESET_STATE = "payment/RESET_STATE";

export const STATS_RESET_STATE = "statistics/RESET_STATE";

export const TEAMS_RESET_STATE = "teams/RESET_STATE";

export const UPDATE_ACTIVE_FILTERS = "UPDATE_ACTIVE_FILTERS";

<template>
  <v-card class="mb-2">
    <v-img
      :height="bannerHeight"
      gradient="to bottom left, rgba(100,115,201,.5), rgba(25,32,72,.8)"
      src="/meeting-bg.jpeg"
      width="100%"
    >
      <v-container fill-height fluid class="white--text">
        <v-layout align-center row wrap>
          <v-flex xs12 sm8 md9 lg10>
            <h1 class="card-title display-2 mb-2">Who do you know?</h1>
            <span class="subheading">
              6DOS works best when everyone contributes. Help your team find the
              introductions they need by importing your contacts.
            </span>
          </v-flex>
          <v-flex xs12 sm4 md3 lg2>
            <v-btn class="primary" to="/getting-started" dark large block>
              Get Started
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "GettingStartedBanner",
  computed: {
    bannerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350px";
        case "sm":
          return "300px";
        case "md":
          return "300px";
        case "lg":
          return "200px";
        case "xl":
          return "180px";
        default:
          return "180px";
      }
    },
  },
};
</script>

<template>
  <div class="footer">
    <v-footer
      v-if="$vuetify.breakpoint.mdAndUp"
      class="navy px-3 elevation-0"
      height="50px"
      app
      dark
    >
      v{{ version }}
      <v-spacer></v-spacer>
      <span>
        &copy; {{ new Date().getFullYear() }} {{ $whiteLabelConfig.copyright }}
      </span>
      <v-btn href="https://6dos.co/user-agreement/" small flat>
        Terms of Service
      </v-btn>
      <v-btn href="https://6dos.co/privacy-policy/" small flat>
        Privacy Policy
      </v-btn>
    </v-footer>
    <MobileAppFooter v-if="$vuetify.breakpoint.smAndDown" />
  </div>
</template>

<script>
import { version } from "../../package.json";
import { mapGetters, mapActions } from "vuex";
import MobileAppFooter from "./MobileAppFooter";

export default {
  name: "AppFooter",
  components: {
    MobileAppFooter,
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
    isAuthenticated() {
      return this.profile.id != null;
    },
    version() {
      return version;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    requestLogout() {
      this.logout().then(() => {
        this.$router.push("/auth");
      });
    },
  },
};
</script>

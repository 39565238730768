import StatsApi from "@/api/statistics";
import Vue from "vue";

export const FETCH_USER_STATS_REQUEST = "FETCH_USER_STATS_REQUEST";
export const FETCH_USER_STATS_SUCCESS = "FETCH_USER_STATS_SUCCESS";
export const FETCH_USER_STATS_FAILURE = "FETCH_USER_STATS_FAILURE";

export const FETCH_TEAM_STATS_REQUEST = "FETCH_TEAM_STATS_REQUEST";
export const FETCH_TEAM_STATS_SUCCESS = "FETCH_TEAM_STATS_SUCCESS";
export const FETCH_TEAM_STATS_FAILURE = "FETCH_TEAM_STATS_FAILURE";

export const RESET_STATE = "RESET_STATE";

const state = {
  loading: false,
  error: null,
  userStats: {},
  teamStats: {},
  hasImported: true,
};

const getters = {
  isLoading: (state) => {
    return state.loading;
  },

  getUserStats: (state) => {
    return state.userStats;
  },

  getTeamStats: (state) => {
    return state.teamStats;
  },

  hasImported: (state) => {
    return state.hasImported;
  },
};

const actions = {
  fetchUserStats({ commit }, { userId }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_USER_STATS_REQUEST);
      StatsApi.fetchUserStats(userId)
        .then((resp) => {
          commit(FETCH_USER_STATS_SUCCESS, {
            userStatsRawData: resp.data,
            userId: userId,
          });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_USER_STATS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchTeamStats({ commit }, { teamId }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_TEAM_STATS_REQUEST);
      StatsApi.fetchTeamStats(teamId)
        .then((resp) => {
          commit(FETCH_TEAM_STATS_SUCCESS, {
            teamStatsRawData: resp.data,
            teamId: teamId,
          });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_TEAM_STATS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },
};

const mutations = {
  [FETCH_USER_STATS_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_USER_STATS_SUCCESS](state, { userStatsRawData, _userId }) {
    state.loading = false;
    state.error = null;

    Vue.set(state, "userStats", userStatsRawData);
    state.hasImported = userStatsRawData.imported;
  },

  [FETCH_USER_STATS_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [FETCH_TEAM_STATS_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_TEAM_STATS_SUCCESS](state, { teamStatsRawData, _teamId }) {
    state.loading = false;
    state.error = null;

    Vue.set(state, "teamStats", teamStatsRawData);
  },

  [FETCH_TEAM_STATS_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [RESET_STATE](state) {
    const initialState = {
      loading: false,
      error: null,
      userStats: {},
      teamStats: {},
      hasImported: false,
    };
    Object.assign(state, initialState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

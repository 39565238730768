import * as types from "./mutation-types";
import Vue from "vue";

import whitelabelConfig from "@/whitelabel.config";

function getWhiteLabelConfig() {
  return whitelabelConfig[process.env.VUE_APP_BRAND];
}

export default {
  [types.LOGIN_REQUEST](state) {
    state.fetchingData = true;
    state.error = null;
  },

  [types.LOGIN_SUCCESS](state, { email, token }) {
    state.fetchingData = false;
    state.error = null;

    localStorage.setItem("email", email);
    Vue.cookie.set("token", token, {
      expires: "90D",
      domain: getWhiteLabelConfig().domain,
    });

    state.email = email;
  },

  [types.LOGIN_FAILURE](state, { error }) {
    state.fetchingData = false;
    state.error = error;
  },

  [types.LOGOUT](state) {
    Vue.cookie.delete("token", {
      domain: getWhiteLabelConfig().domain,
    });

    localStorage.removeItem("profile");
    localStorage.removeItem("account");
    localStorage.removeItem("selectedAccount");
    localStorage.clear();

    state.email = null;
    state.profile = null;
    state.selectedAccount = null;
  },

  [types.FETCH_PROFILE_REQUEST](state) {
    state.fetchingData = true;
    state.error = null;
  },

  [types.FETCH_PROFILE_SUCCESS](state, { profile }) {
    localStorage.setItem("profile", JSON.stringify(profile));
    state.profile = profile;
  },

  [types.FETCH_PROFILE_FAILURE](state, { error }) {
    state.fetchingData = false;
    state.error = error;
  },

  [types.FETCH_ACCOUNTS_REQUEST](state) {
    state.fetchingData = true;
    state.error = null;
  },

  [types.FETCH_ACCOUNTS_SUCCESS](state, { accounts }) {
    state.accountIds = accounts.map((account) => account.id);
    state.accounts = accounts.reduce((newAccounts, account) => {
      newAccounts[account.id] = Object.assign(
        state.accounts[account.id] || {},
        account,
      );
      return newAccounts;
    }, {});

    if (state.selectedAccount === null && accounts.length > 0) {
      let account = state.accounts[Object.keys(state.accounts)[0]];
      Vue.set(state, "selectedAccount", account);
      localStorage.setItem("selectedAccount", JSON.stringify(account));
    }

    if (accounts.length === 0) {
      state.selectedAccount = null;
      localStorage.removeItem("selectedAccount");
    }
  },

  [types.FETCH_ACCOUNTS_FAILURE](state, { error }) {
    state.fetchingData = false;
    state.error = error;
  },

  [types.UPDATE_ACTIVE_FILTERS](state, { filters }) {
    Vue.set(state, "activeFilters", filters);
  },
};

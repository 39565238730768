<template>
  <v-container grid-list-lg fluid>
    <AppHeader />
    <v-content fill-height>
      <v-layout row justify-center>
        <v-flex xs12 md8>
          <v-layout row wrap>
            <v-flex grow>
              <PageTitle value="My Contacts" />
            </v-flex>
          </v-layout>
          <v-layout v-if="hasDuplicates" row justify-center>
            <v-flex xs12>
              <v-card class="warning my-3" dark>
                <v-layout row align-center>
                  <v-flex grow>
                    <v-card-text>
                      <strong v-text="totalDuplicatesText" />
                    </v-card-text>
                  </v-flex>
                  <v-flex shrink>
                    <v-btn to="/contacts/duplicates" dark flat large>
                      <v-icon left>layers</v-icon>
                      Merge & Fix
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row justify-center>
            <v-flex xs12>
              <v-text-field
                v-model="search"
                label="Search"
                prepend-inner-icon="search"
                dense
                single-line
                solo
                @input="debounceFetchContacts"
              >
                <template slot="append">
                  <v-chip>{{ $n(pagination.totalItems) }}</v-chip>
                </template>
              </v-text-field>
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="contacts"
                  :loading="isLoading"
                  :pagination.sync="pagination"
                  :rows-per-page-items="rowsPerPageOptions"
                  :total-items="pagination.totalItems"
                  item-key="contact_id"
                  show-select
                >
                  <v-progress-linear
                    color="primary"
                    slot="progress"
                    indeterminate
                  />
                  <template slot="items" slot-scope="props">
                    <tr @click="selectDetailContact(props.item)">
                      <td class="pl-4 pr-0">
                        <v-checkbox
                          :input-value="
                            selectedContacts.includes(props.item.contact_id)
                          "
                          class="px-0"
                          color="primary"
                          hide-details
                          @click.stop="toggleCheckbox(props.item.contact_id)"
                        />
                        <v-btn
                          v-if="showMergeButton(props.item.contact_id)"
                          class="merge-callout-button"
                          color="primary"
                          @click.stop="mergeSelectedContacts"
                        >
                          Merge Contacts
                        </v-btn>
                      </td>
                      <td class="pl-2">
                        <v-layout row align-center>
                          <v-flex shrink>
                            <Avatar :username="props.item.name" :size="34" />
                          </v-flex>
                          <v-flex>
                            <span>{{ props.item.name }}</span>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>{{ props.item.organization }}</td>
                      <td>{{ props.item.title }}</td>
                      <td>{{ getValueByType(props.item.info, "email") }}</td>
                      <td>
                        {{ getValueByType(props.item.info, "phone_number") }}
                      </td>
                      <td class="pr-0">
                        <font-awesome-icon
                          v-for="(source, index) in props.item.sources"
                          :icon="['fab', iconFor(source)]"
                          :key="index"
                          class="fa-fw mr-1"
                          size="lg"
                        />
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
    <ConnectionDetailDialog
      v-model="contactDetailDialog"
      :connection="detailContact"
      @close="closeContactDetail"
      @delete-connection="deleteContact($event)"
    />
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import Avatar from "vue-avatar";
import ConnectionDetailDialog from "@/components/connection/ConnectionDetailDialog";
import ContactApi from "@/api/contacts";
import {
  faGoogle,
  faApple,
  faMicrosoft,
  faLinkedin,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import PageTitle from "@/components/global/PageTitle.vue";

library.add(faGoogle, faApple, faMicrosoft, faLinkedin, faWindows);

const faIcons = {
  exchange: "microsoft",
  google: "google",
  icloud: "apple",
  linkedin: "linkedin",
  microsoft: "windows",
};

export default {
  name: "ContactsView",
  components: {
    AppHeader,
    Avatar,
    ConnectionDetailDialog,
    "font-awesome-icon": FontAwesomeIcon,
    PageTitle,
  },
  data() {
    return {
      contactDetailDialog: false,
      contacts: [],
      debounceTimeout: null,
      detailContact: { name: "Not Set." },
      headers: [
        { text: "", align: "left", sortable: false },
        { text: "Name", align: "left", value: "name" },
        { text: "Organization", align: "left", value: "organization" },
        { text: "Title", align: "left", value: "title" },
        { text: "Email", align: "left", value: "email", sortable: false },
        {
          text: "Phone Number",
          align: "left",
          value: "phone",
          sortable: false,
        },
        {
          text: "Imported From",
          align: "right",
          value: "source",
          sortable: false,
          width: "10%",
        },
      ],
      isLoading: false,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 50,
        sortBy: "name",
        totalItems: 0,
      },
      rowsPerPageOptions: [15, 50, 100],
      search: "",
      selectedContacts: [],
      totalDuplicates: 0,
    };
  },
  computed: {
    hasDuplicates() {
      return this.totalDuplicates > 0;
    },
    isSearching() {
      return this.search !== "";
    },
    totalDuplicatesText() {
      switch (this.totalDuplicates) {
        case 0:
          return "No duplicates found.";
        case 1:
          return "1 duplicate found.";
        default:
          return `${this.$n(this.totalDuplicates)} duplicates found.`;
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.isLoading) {
          return;
        }
        this.debounceFetchContacts();
      },
    },
    search: {
      handler() {
        if (this.search) {
          this.pagination.sortBy = null;
        } else {
          this.pagination.sortBy = "name";
        }

        this.debounceFetchContacts();
      },
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchContacts(),
      this.fetchTotalContactDuplicates(),
    ]);
  },
  methods: {
    closeContactDetail() {
      this.contactDetailDialog = false;
      this.detailContact = { name: "Not Set." };
    },
    debounceFetchContacts() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.fetchContacts();
      }, 250);
    },
    async deleteContact(_contact_id) {
      this.closeContactDetail();
      await this.fetchContacts();
    },
    async fetchContacts() {
      this.isLoading = true;
      const { descending, sortBy, rowsPerPage, page } = this.pagination;
      const offset = (page - 1) * rowsPerPage;

      const params = {
        limit: rowsPerPage,
        offset: offset,
        q: this.isSearching ? this.search : null,
        sort_by: sortBy,
        sort_dir: descending ? "desc" : "asc",
      };

      try {
        let response = await ContactApi.fetchContacts(params);
        this.contacts = response.data;
        this.pagination.totalItems = response.total;
      } catch (error) {
        console.error("Error fetching contacts:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTotalContactDuplicates() {
      let resp = await ContactApi.fetchContactDuplicates();
      this.totalDuplicates = resp.total;
    },
    getValueByType(infoArray, type, field = "value") {
      const item = infoArray.find((info) => info.type === type && info.current);
      return item ? item[field] : "";
    },
    iconFor(source) {
      return faIcons[source];
    },
    selectDetailContact(connection) {
      this.detailContact = connection;
      this.contactDetailDialog = true;
    },
    showMergeButton(contactId) {
      return (
        this.selectedContacts.length >= 2 &&
        this.selectedContacts[this.selectedContacts.length - 1] === contactId
      );
    },
    toggleCheckbox(contactId) {
      const index = this.selectedContacts.indexOf(contactId);
      if (index === -1) {
        this.selectedContacts.push(contactId);
      } else {
        this.selectedContacts.splice(index, 1);
      }
    },
    async mergeSelectedContacts() {
      this.isLoading = true;
      try {
        await ContactApi.mergeContacts(this.selectedContacts);

        // Eventually document re-indexing needs to be made synchronous
        // on the backend.
        setTimeout(() => {
          this.selectedContacts = [];
          this.fetchContacts();
        }, 2000);
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "notifs",
          title: "Request Failed",
          text: "Something went wrong.",
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.merge-callout-button {
  transform: translate(-180px, -35px);
  position: absolute;
  z-index: 999;
}
</style>
